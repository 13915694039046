<!-- /* eslint-disable */-->
<template>
  <v-container>
    <!-- Main Title -->
    <v-row no-gutters>
      <v-col cols="12">
        <h1>플레이스 검색량 1회 조회</h1>
      </v-col>
      <v-col cols="12" style="margin:20px 0;">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>가이드</v-expansion-panel-header>
            <v-expansion-panel-content>
              - 플레이스 URL주소는 https://m.place.naver.com/~ 로 시작되는 전체URL주소를 넣어야 정확한 검색이 가능합니다.<br>
              - 조회한 정보는 일정 기간 경과 시 자동으로 삭제될 수 있습니다.<br>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <!-- Collapsible Description -->
    <v-row no-gutters>
      
      <v-col cols="12" style="text-align:right; margin:10px 0;">
        <v-btn style="margin-right:5px;" color="primary" dense @click="openInputDialog">
          등록
        </v-btn>
      </v-col>
    </v-row>
    <!-- Table (takes the remaining space) -->
    <v-row no-gutters class="flex-grow-1">
      <v-col cols="12" style="text-align:right; margin:10px 0;">
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="no"
          show-select
          v-model:selected="selected"
          hide-default-footer
          :page.sync="page"
          :items-per-page="pagePer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>플레이스 목록</v-toolbar-title>
              <span class="ml-3"> 등록 개수 : {{ totalCount }}</span>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchText"
                label="플레이스/키워드 명"
                outlined
                dense
                hide-details
                style="max-width: 250px; margin-right: 10px;"
                @keyup.enter="getContents"
              ></v-text-field>
              <v-btn color="primary" @click="getContents" style="margin-right: 10px;">검색</v-btn>
            </v-toolbar>

            <div class="d-flex flex-column justify-end align-content-end mr-3" style="align-items: end;" >
              <span class="mr-5">페이지 당 조회수</span>
              <v-select
                  v-model="pagePer"
                  :items="pagePerItems"
                  outlined
                  dense
                  hide-details
                  return-object
                  single-line
                  style="max-width: 150px;"
                  class="custom-select"
              ></v-select>
            </div>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <template v-for="item in items">
                <tr :key="item.no" class="text-center">
                  <td rowspan="2">
                    <v-checkbox
                      v-model="selected"
                      :value="item"
                    ></v-checkbox>
                  </td>
                  <td @click="MoveToSearch(item.keyword)" class="href-effect" >{{ item.keyword }}</td>
                  <td class="place-url">{{ item.placeUrl }}</td>
                  <td @click="MoveToPlaceUrl(item.placeUrl)" class="href-effect place-responsive-text">{{ item.name }}</td>
                  <td class="place-responsive-text">{{ parseInt(item.placeCount).toLocaleString() }} 개</td>
                  <td class="place-responsive-text">{{ isNaN(parseInt(item.monthSearchCntPc)) ? item.monthSearchCntPc : parseInt(item.monthSearchCntPc).toLocaleString() }} 회</td>
                  <td class="place-responsive-text">{{ isNaN(parseInt(item.monthSearchCntMobile)) ? item.monthSearchCntMobile : parseInt(item.monthSearchCntMobile).toLocaleString() }} 회</td>
                  <td class="place-responsive-text">{{ item.createTime }}</td>
                  <td class="place-responsive-text">{{ item.saveCount }} 회</td>
                  <td class="place-responsive-text">{{ parseInt(item.blogCafeReviewCount.replaceAll(',', '')).toLocaleString() }} 회</td>
                  <td class="place-responsive-text">{{ parseInt(item.visitorReviewCount.replaceAll(',', '')).toLocaleString() }} 회</td>
                  <td>
                    <v-btn style="margin-top:5px; color:#FFF; margin-right: 5px;" small color="red" @click="deletePlace(item)">삭제</v-btn>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
        <v-pagination v-model="page" :length="pageCount" @input="getContents"></v-pagination>
      </v-col>
    </v-row>

    <v-dialog v-model="inputDialog" persistent max-width="500px">
    <div ref="report">
      <v-card>
        <v-card-title>
          <span class="text-h5">플레이스 추가</span>
        </v-card-title>

        <v-card-text>
          <h3>플레이스 주소는 https://m.place.naver.com/~ 의 URL을 정확히 입력해야 합니다.</h3>
          <v-form>
            <!-- Input Field 1 -->
            <v-text-field label="검색어" v-model="input_keyword"></v-text-field>

            <!-- Input Field 2 -->
            <v-text-field label="플레이스 주소" v-model="input_place_url"></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions style="text-align: right;">
          <!-- Button to close Dialog -->
          <v-spacer></v-spacer>
          <!-- Cancel button -->
          <v-btn color="secondary" text @click="inputDialog = false">취소</v-btn>
          <!-- Add button -->
          <v-btn color="primary" @click="addEntry()" :disabled="inputProgress">추가</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
  <v-snackbar
    v-model="snackbar"
    color="primary"
    variant="tonal"
    :timeout="2000"
  >
    {{ snackText }}
  </v-snackbar>
  </v-container>
</template>
  
<script>
import { wrapFetch } from '@/config';

export default {
  data() {
    return {
      snackbar: false,
      snackText: "",
      selected: [],
      headers: [
        { text: '검색 키워드', value: 'keyword', align:'center'},
        { text: '플레이스 URL 주소', value: 'placeUrl', align:'center'},
        { text: '플레이스 업체명', value: 'name', align:'center'},
        { text: '업체수', value: 'placeCount', align:'center'},
        { text: '월 조회수(PC)', value: 'monthSearchCntPc', align:'center'},
        { text: '월 조회수(모바일)', value: 'monthSearchCntMobile', align:'center'},
        { text: '등록일', value: 'createTime', align:'center'},
        { text: '저장수', value: 'saveCount', align:'center'},
        { text: '블로그리뷰수', value: 'blogCafeReviewCount', align:'center'},
        { text: '방문자리뷰수', value: 'visitorReviewCount', align:'center'},
        { text: '', value: 'utillity' },
      ],
      searchText: "",
      inputDialog: false,
      inputProgress: false,
      input_keyword: "",
      input_place_url: "",
      items: [],
      pagePerItems: [5, 10, 20, 100],
      totalCount: 0,
      page: 1,
      pagePer: 5,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.totalCount / this.pagePer)
    },
  },
  methods: {
    getContents(){
      let self = this;
      self.items = [];
      self.selected = [];

      wrapFetch(`/place/once?searchText=${self.searchText}&page=${self.page}&pagePer=${self.pagePer}`, "GET")
      .then((data) => {
        this.totalCount = data.totalCount
        data.placeList.map((e) => {
          e.loading = false;
          self.items.push(e);
        })
      })
    },
    
    addEntry(){
      let self = this;
      self.inputProgress = true;
      
      wrapFetch('/place/once', "POST", {
        keyword: self.input_keyword,
        placeUrl: self.input_place_url,
      })
      .then(() => {
        self.snackText = "플레이스 검색이 완료되었습니다.";
        self.snackbar = true;
        self.inputDialog = false;
        self.inputProgress = false;
        self.getContents();
      }).catch(() => {
        self.inputProgress = false;
      })
    },
    openInputDialog(){
      this.input_keyword = "";
      this.input_place_url = "";
      this.inputDialog = true;
    },
    deletePlace(item) {
      if(confirm(`${item.name} 검색 기록을 정말 삭제하시겠습니까?`)){
        wrapFetch(`/place/once?no=${item.no}`, "DELETE")
        .then(() => {
          self.snackText = "검색 기록 삭제가 완료되었습니다.";
          self.snackbar = true;
          this.getContents();
        })
      }
    },
    MoveToSearch(keyword) {
      window.open('https://search.naver.com/search.naver?query=' + encodeURIComponent(keyword), '_blank')
    },
    MoveToPlaceUrl(url) {
      window.open(url, '_blank')
    }
  },
  mounted() {
    this.getContents();
  }
};
</script>
  
<style>
.flex-grow-1 {
  flex-grow: 1;
}
v-container.fill-height {
  height: 100vh;
}
.v-data-table__wrapper > table > tbody > tr:hover {
  background: inherit !important;
}
.rankCount{
  font-weight: bold;
}
.blogCafeReviewCount {
  font-size: 0.9em;
  color: #3f51b5;
  font-weight: bold;
}
.visitorReviewCount {
  font-size: 0.9em;
  color: #F9A825;
  font-weight: bold;
}
.saveCount {
  font-size: 0.9em;
  color: #BF360C;
  font-weight: bold;
}
.href-effect {
  color: #0056b3;
  font-weight:bold;
}
.href-effect:hover {
  cursor: pointer
}
.border-bottom-solid {
  border-bottom: 2px solid rgba(0, 0, 0, 0.3) !important;
}
</style>